import { ClientProjectEntity, ClientSiteEntity, ClientSyncCoreEntity } from '@edgebox/api-rest-client';
import { CustomerRole, UserType } from '@edgebox/data-definitions';
import { EditButton, LeftRightContainer, Right } from '@edgebox/react-components';
import React from 'react';
import { Button, Col, Image, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { ApiComponent, ContractLink, CustomerLink, IApiComponentState, ProjectForm, SyncCoreLink } from '../../../common/index';
import { ILocationProp, INavigateProp, IProjectParamProp, withLocationAndNavigate, withProjectParam } from '../../RouterHelper';
import { ContentBox } from '../../Shared/ContentBox';
import ProjectDetailImage from '../../../images/undraw_Mind_map_re_nlb6.svg';
import { IconSummaryItem } from '../../Shared/IconSummaryItem';
import { faFingerprint } from '@fortawesome/pro-light-svg-icons/faFingerprint';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign';
import { ProjectStatus } from '@edgebox/sync-core-data-definitions';
import { HasNone } from '../../../common/helpers/HasSwitch';
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';
import { propertyChangeReload } from '../../../common/helpers/propertyChangeReload';
import { IAppContextProp, withAppContext } from '../../../common/contexts/AppContext';
import { ProjectIcons } from '../../Icons';
import { UserRoleGate } from '../../../common/components/UserRoleGate';
import { Embed } from '../../Shared/Embed';

interface IExternalProps {
  tab: 'notifications' | 'details';
}
interface IProps extends JSX.IntrinsicAttributes, IExternalProps, IProjectParamProp, IAppContextProp, INavigateProp, ILocationProp {}

interface IState extends IApiComponentState {
  project?: ClientProjectEntity;
  editProject?: ClientProjectEntity;
  syncCore?: ClientSyncCoreEntity;
  anyActiveSite?: ClientSiteEntity;
  saving?: boolean;
  confirmAction?: 'deactivate' | 'reactivate';
}

class ProjectDetailComponent extends ApiComponent<IProps, IState> {
  async load() {
    const project = await this.api.syndication.projects.item(this.props.project);

    this.props.appContext.setProject?.(project);

    let syncCore: ClientSyncCoreEntity | undefined = undefined;
    let anyActiveSite: ClientSiteEntity | undefined = undefined;

    if (
      this.api.currentUser?.customerRoles?.includes(CustomerRole.Owner) ||
      this.api.currentUser?.customerRoles?.includes(CustomerRole.ManageSites)
    ) {
      syncCore = await project?.syncCore?.get();
      const sites = await this.api.billing.sites.search({ itemsPerPage: 1, page: 0 }, { projectId: project?.id });
      anyActiveSite = sites.items[0];
    }

    return {
      project,
      syncCore,
      anyActiveSite,
    };
  }

  render() {
    const { tab } = this.props;
    const { project, editProject, saving, confirmAction, syncCore, anyActiveSite } = this.state;

    if (!project) {
      return this.renderRequest();
    }

    const renderBackendProperties = this.api.currentUser?.type === UserType.Internal;

    const notificationEmbed = syncCore && anyActiveSite && (
      <>
        <ContentBox className="pb-3">
          <Embed embed="notifications" scope="configuration" syncCore={syncCore} site={anyActiveSite} />
        </ContentBox>
      </>
    );

    return (
      <>
        <LeftRightContainer
          left={
            <HeadlineWithBreadcrumbNavigation>
              <span className="text-truncate mw-100 d-inline-block">
                <ProjectIcons project={project} />
                <span className="ms-3" title={project.name}>
                  {project.name}
                </span>{' '}
              </span>
            </HeadlineWithBreadcrumbNavigation>
          }
          right={
            <>
              {!project.status || project.status === ProjectStatus.Active ? (
                <HasNone request={(api) => api.billing.sites.search(undefined, { projectId: project.id })}>
                  <Button variant="danger" onClick={() => this.setState({ confirmAction: 'deactivate' })}>
                    Deactivate
                  </Button>
                </HasNone>
              ) : (
                <Button variant="primary" onClick={() => this.setState({ confirmAction: 'reactivate' })}>
                  Reactivate
                </Button>
              )}
              {editProject ? null : (
                <UserRoleGate allowed={[CustomerRole.Owner, CustomerRole.ManageSites]}>
                  {() => <EditButton onClick={() => this.setState({ editProject: new ClientProjectEntity(project) })} />}
                </UserRoleGate>
              )}
            </>
          }
        />

        <Tabs id="project-tabs" activeKey={tab} onSelect={(activeTab) => this.props.navigate(`/projects/${project.id}/${activeTab}`)}>
          <Tab eventKey="details" title={'Details'}>
            <ContentBox className="pb-3">
              <Row>
                <Col xs={6} className={'p-5'}>
                  <Image style={{ marginLeft: '25%' }} src={ProjectDetailImage} width={'50%'} />
                </Col>
                <Col className={'pt-4'}>
                  {editProject ? (
                    <ProjectForm
                      fixedContract
                      key={editProject ? 'edit' : 'view'}
                      name={<></>}
                      onSave={async (update) => {
                        this.setState({
                          saving: true,
                        });
                        try {
                          const project = await this.api.syndication.projects.update(update as ClientProjectEntity);
                          this.setState({
                            project,
                            editProject: undefined,
                          });

                          this.props.appContext.refreshProjects?.();
                        } finally {
                          this.setState({
                            saving: false,
                          });
                        }
                      }}
                      mode={editProject ? 'edit-full' : 'view-full'}
                      entity={editProject || project}
                    >
                      {editProject && (
                        <Right>
                          <Button disabled={saving} variant="light" onClick={() => this.setState({ editProject: undefined })}>
                            Cancel
                          </Button>
                          <Button disabled={saving} type="submit" variant="primary">
                            Save
                          </Button>
                        </Right>
                      )}
                    </ProjectForm>
                  ) : (
                    <>
                      <h4>Details</h4>
                      {renderBackendProperties && (
                        <IconSummaryItem icon={faBuilding}>
                          <CustomerLink entityId={project.customer.getId()} />
                        </IconSummaryItem>
                      )}
                      <IconSummaryItem icon={faDollarSign}>
                        <ContractLink entityId={project.contract.getId()} />
                      </IconSummaryItem>
                      {!!project.syncCore?.getId() && (
                        <IconSummaryItem icon={faServer}>
                          <SyncCoreLink entityId={project.syncCore.getId()!} />
                        </IconSummaryItem>
                      )}
                      <IconSummaryItem icon={faFingerprint}>{project.uuid}</IconSummaryItem>
                    </>
                  )}
                </Col>
              </Row>
            </ContentBox>
          </Tab>
          <Tab eventKey="notifications" title={'Notifications'}>
            {notificationEmbed}
          </Tab>
        </Tabs>

        <Modal show={!!confirmAction} scrollable onHide={() => this.setState({ confirmAction: undefined })}>
          <Modal.Header closeButton>
            <Modal.Title>{confirmAction === 'deactivate' ? 'Deactivate project' : 'Reactivate project'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {confirmAction === 'deactivate' ? (
              <>
                <strong>Are you sure you want to deactivate this project?</strong>
                <br />
                <br />
                You won't be able to add new sites to this project or reactivate existing sites, but you can reactivate the project later.
              </>
            ) : (
              <>
                <strong>Are you sure you want to reactivate this project?</strong>
                <br />
                <br />
                You will be able to add new sites to this project again and reactivate sites in this project.
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Right>
              <Button
                disabled={saving}
                variant={confirmAction === 'deactivate' ? 'danger' : 'primary'}
                onClick={async () => {
                  this.setState({ saving: true });
                  try {
                    project.status = confirmAction === 'deactivate' ? ProjectStatus.Inactive : ProjectStatus.Active;
                    const updatedProject = await this.api.syndication.projects.update(project);
                    this.setState({ project: updatedProject, confirmAction: undefined });
                  } finally {
                    this.setState({ saving: false });
                  }
                }}
              >
                Continue
              </Button>
            </Right>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export const ProjectDetail = withProjectParam<IExternalProps>(
  withLocationAndNavigate(propertyChangeReload<any>(withAppContext<any>(ProjectDetailComponent), (props) => props.project))
);
