import * as Sentry from '@sentry/react';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { BackendAddContract } from './Backend/Billing/BackendAddContract';
import { BackendAddContractRevision } from './Backend/Billing/BackendAddContractRevision';
import { BackendCustomerDetail } from './Backend/Billing/BackendCustomerDetail';
import { BackendCustomerList } from './Backend/Billing/BackendCustomerList';
import { BackendInvoiceList } from './Backend/Billing/BackendInvoiceList';
import { BackendSettings } from './Backend/settings/BackendSettings';
import { BackendLogs } from './Backend/Syndication/BackendLogs';
import { BackendProjectList } from './Backend/Syndication/BackendProjectList';
import { BackendSiteList } from './Backend/Syndication/BackendSiteList';
import { BackendSyncCoreList } from './Backend/Syndication/BackendSyncCoreList';
import { AccountCompany } from './Customer/Account/AccountCompany';
import { AccountBilling } from './Customer/Account/AccountBilling';
import { AccountPayment } from './Customer/Account/AccountPayment';
import { AccountProfile } from './Customer/Account/AccountProfile';
import { AccountUsers } from './Customer/Account/AccountUsers';
import { CheckoutPage } from './Customer/CheckoutPage';
import { ContentDashboard } from './Customer/Syndication/ContentDashboard';
import { ContractDetail } from './Customer/Syndication/ContractDetail';
import { ContractList } from './Customer/Syndication/ContractList';
import { ContractUpgrade } from './Customer/Syndication/ContractUpgrade';
import { ProjectDetail } from './Customer/Syndication/ProjectDetail';
import { ProjectList } from './Customer/Syndication/ProjectList';
import { RegisterMultipleSites } from './Customer/Syndication/RegisterMultipleSites';
import { RegisterSite } from './Customer/Syndication/RegisterSite';
import { SiteDetail } from './Customer/Syndication/SiteDetail';
import { SiteList } from './Customer/Syndication/SiteList';
import { SyncCoreDetail } from './Customer/Syndication/SyncCoreDetail';
import { SyncCoreList } from './Customer/Syndication/SyncCoreList';
import { NotFoundPage } from './NotFoundPage';
import { ILocationProp, WithParams, withLocation } from './RouterHelper';
import { SidebarNavigation } from './SidebarNavigation';
import { Dashboard } from './Customer/Dashboard';
import { ApiComponent, IApiComponentState } from '../common';
import { BackendContracts } from './Backend/Billing/BackendContracts';
import { GetStartedInstructions } from './Customer/GetStartedInstructions';
import { WithSite } from './WithSite';
import { WithAppContext } from '../common/contexts/AppContext';

interface IExternalProps {}
interface IProps extends IExternalProps, ILocationProp {}

interface IState extends IApiComponentState {}

export class RoutingContainerClass extends ApiComponent<IProps, IState> {
  async load() {
    return {};
  }

  public render() {
    let sidebar: React.ReactNode = this.props.location.pathname === '/sites/register' ? undefined : <SidebarNavigation />;

    return (
      <div className={'d-flex align-items-stretch flex-columns flex-grow-1'}>
        <div className={'flex-grow-0'}>{sidebar}</div>
        <div className={'flex-grow-1'}>
          <div id="page-content" className="overflow-auto">
            <div
              className={`content ${
                this.props.location.pathname.substring(0, 8) === '/content' ||
                this.props.location.pathname.substring(0, 8) === '/updates' ||
                this.props.location.pathname.match(/^\/projects\/[a-z0-9]+\/(content|updates)/) ||
                this.props.location.pathname.substring(0, 25) === '/backend/syndication/logs'
                  ? 'p-3'
                  : 'container'
              }`}
              key={'content'}
            >
              <Sentry.ErrorBoundary>
                <Routes>
                  <Route
                    path=""
                    element={
                      <WithAppContext>
                        {(appContext) =>
                          appContext?.project?.id ? <Navigate to={`/projects/${appContext.project.id}/dashboard`} replace /> : <Dashboard />
                        }
                      </WithAppContext>
                    }
                  />

                  <Route path="account">
                    <Route path="" element={<Navigate to={'/account/profile'} replace />} />

                    <Route path="profile" element={<AccountProfile />} />
                    <Route path="users" element={<AccountUsers />} />
                    <Route path="billing" element={<AccountBilling />} />
                    <Route path="payment" element={<AccountPayment />} />
                    <Route path="company" element={<AccountCompany />} />

                    <Route
                      path="notifications"
                      element={
                        <WithAppContext>
                          {(appContext) => <Navigate to={`/projects/${appContext?.project?.id}/notifications`} replace />}
                        </WithAppContext>
                      }
                    />
                  </Route>

                  <Route path="sites/register" element={<RegisterSite />} />
                  <Route path="sites/register-multiple" element={<RegisterMultipleSites />} />

                  <Route path="projects" element={<ProjectList />} />
                  <Route path="projects/:project" element={<Navigate to={this.props.location.pathname + '/details'} />} />
                  <Route path="projects/:project/details" element={<ProjectDetail tab="details" />} />
                  <Route path="projects/:project/notifications" element={<ProjectDetail tab="notifications" />} />
                  <Route
                    path="projects/:project/dashboard"
                    element={
                      <WithParams>
                        {(params) => (
                          <WithAppContext>
                            {(appContext) =>
                              appContext?.project?.id && appContext?.project?.id === params.project && appContext?.site?.id ? (
                                <Navigate to={`/projects/${appContext.project.id}/dashboard/${appContext.site.id}`} replace />
                              ) : (
                                <Dashboard />
                              )
                            }
                          </WithAppContext>
                        )}
                      </WithParams>
                    }
                  />
                  <Route path="projects/:project/dashboard/:site" element={<Dashboard />} />
                  <Route path="projects/:project/sites" element={<SiteList />} />
                  <Route path="projects/:project/sites/:site" element={<Navigate to={this.props.location.pathname + '/stats'} />} />
                  <Route path="projects/:project/sites/:site/:tab" element={<SiteDetail />} />
                  <Route
                    path="projects/:project/updates"
                    element={
                      <WithParams>
                        {(params) => (
                          <WithAppContext>
                            {(appContext) =>
                              appContext?.project?.id && appContext?.project?.id === params.project && appContext?.site?.id ? (
                                <Navigate to={`/projects/${appContext.project.id}/updates/${appContext.site.id}`} replace />
                              ) : (
                                <ContentDashboard activeTab="synchronizations" />
                              )
                            }
                          </WithAppContext>
                        )}
                      </WithParams>
                    }
                  />
                  <Route path="projects/:project/updates/:site" element={<ContentDashboard activeTab="synchronizations" />} />
                  <Route
                    path="projects/:project/content"
                    element={
                      <WithParams>
                        {(params) => (
                          <WithAppContext>
                            {(appContext) =>
                              appContext?.project?.id && appContext?.project?.id === params.project && appContext?.site?.id ? (
                                <Navigate to={`/projects/${appContext.project.id}/content/${appContext.site.id}`} replace />
                              ) : (
                                <ContentDashboard />
                              )
                            }
                          </WithAppContext>
                        )}
                      </WithParams>
                    }
                  />
                  <Route path="projects/:project/content/:site" element={<ContentDashboard />} />

                  <Route
                    path="sites"
                    element={
                      <WithAppContext>
                        {(appContext) => <Navigate to={`/projects/${appContext?.project?.id}/sites`} replace />}
                      </WithAppContext>
                    }
                  />
                  <Route
                    path="sites/:id"
                    element={
                      <WithParams>
                        {(params) => (
                          <WithSite entityId={params?.id}>
                            {(site) => <Navigate to={`/projects/${site?.project.getId()}/sites/${params?.id}`} replace />}
                          </WithSite>
                        )}
                      </WithParams>
                    }
                  />

                  <Route
                    path="updates"
                    element={
                      <WithAppContext>
                        {(appContext) => <Navigate to={`/projects/${appContext?.project?.id}/updates`} replace />}
                      </WithAppContext>
                    }
                  />
                  <Route
                    path="content"
                    element={
                      <WithAppContext>
                        {(appContext) => <Navigate to={`/projects/${appContext?.project?.id}/content`} replace />}
                      </WithAppContext>
                    }
                  />

                  <Route path="subscriptions" element={<ContractList />} />
                  <Route path="subscriptions/:id" element={<ContractDetail />} />
                  <Route path="subscriptions/:contract/revisions/:id/upgrade" element={<ContractUpgrade />} />
                  <Route path="subscriptions/:contract/revisions/:id/renew" element={<ContractUpgrade />} />

                  <Route path="sync-cores" element={<SyncCoreList />} />
                  <Route path="sync-cores/:id" element={<SyncCoreDetail />} />

                  <Route path="get-started" element={<GetStartedInstructions />} />

                  <Route path="checkout" element={<Navigate to={'/'} replace />} />
                  <Route path="checkout/:id" element={<CheckoutPage />} />

                  <Route path="backend">
                    <Route path="" element={<Navigate to={'/backend/billing'} replace />} />

                    <Route path="settings" element={<BackendSettings />} />

                    <Route path="billing">
                      <Route path="" element={<Navigate to={'/backend/billing/customers'} replace />} />

                      <Route path="invoices/" element={<BackendInvoiceList />} />
                      <Route path="invoices/:status" element={<BackendInvoiceList />} />
                      <Route path="customers" element={<BackendCustomerList />} />
                      <Route path="customers/:id" element={<BackendCustomerDetail />} />
                      <Route path="customers/:id/contracts/add" element={<BackendAddContract />} />
                      <Route path="customers/:customerId/contracts/:id/add" element={<BackendAddContractRevision />} />
                      <Route path="contracts" element={<BackendContracts />} />
                      <Route path="contracts/:tab" element={<BackendContracts />} />
                      <Route path="contracts/:tab/:id" element={<BackendContracts />} />
                      <Route path="contracts/:id" element={<BackendContracts />} />
                    </Route>

                    <Route path="syndication">
                      <Route path="" element={<Navigate to={'/account/syndication/sites'} replace />} />

                      <Route path="projects" element={<BackendProjectList />} />
                      <Route path="sites" element={<BackendSiteList />} />
                      <Route path="sync-cores" element={<BackendSyncCoreList />} />
                      <Route path="logs" element={<BackendLogs />} />
                    </Route>
                  </Route>

                  <Route element={<NotFoundPage />} />
                </Routes>
              </Sentry.ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const RoutingContainer = withLocation<IExternalProps>(RoutingContainerClass);
